<template>
  <v-responsive>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <div
        class="mx-auto"
        style="max-width: 320px; padding: 30px 0; line-height: 1.8"
      >
        <h1 class="text-center mt-10 mb-5">회원가입</h1>
        <h3
          class="mb-10 text-center"
          style="font-size: 1.2em; font-weight: normal"
        >
          <strong class="primary--text">로또7</strong>은 전화번호만으로<br />
          <strong class="primary--text">간편하게</strong> 사용할 수 있어요.
        </h3>

        <v-form
          @submit.prevent="formSubmit"
          method="post"
          ref="registerForm"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            label="전화번호를 입력해주세요."
            :readonly="verify_otp"
            outlined
            v-model="username"
            type="number"
            maxlength="11"
            :rules="[usernameRules.required, usernameRules.min]"
            placeholder="-를 제외하고 입력해주세요."
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>

          <v-text-field
            v-if="username.length == 11"
            :readonly="verify_otp"
            v-model="password"
            outlined
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="비밀번호"
            hint="8자 이상으로 입력해주세요."
            counter
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field
            v-if="username.length == 11"
            :readonly="verify_otp"
            block
            v-model="verify"
            outlined
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, passwordMatch]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="비밀번호 확인"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field
            label="인증번호"
            v-if="verify_otp"
            ref="otp"
            outlined
            v-model="otp"
            type="text"
            maxlength="6"
            placeholder="인증번호를 입력해주세요."
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>

          <v-btn
            v-if="!verify_otp"
            type="submit"
            block
            x-large
            color="primary"
            class="font-weight-bold"
          >
            인증번호 요청하기
          </v-btn>
          <v-btn
            type="button"
            v-if="verify_otp"
            block
            x-large
            color="primary"
            class="font-weight-bold"
            @click.prevent="JoinSubmit()"
          >
            시작하기
          </v-btn>

          <v-btn
            type="button"
            v-if="verify_otp"
            block
            x-large
            color="primary"
            outlined
            class="font-weight-bold mt-3"
            @click.prevent="verify_otp = false"
          >
            다른번호로 가입하기
          </v-btn>

          <div class="mt-3">
            <small
              >로또7를 사용하시면
              <a @click="goUrl('/policy/privacy')">개인정보 처리방침</a>과
              <a @click="goUrl('/policy/privacy')">이용약관</a>에 동의하시게
              돼요!</small
            >
          </div>

          <!--
                      <v-divider class="my-10 "></v-divider>
        <h3 class="text-center text-weight-normal">간편 로그인</h3>
        -->
        </v-form>
      </div>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";
import axios from "axios";
import qs from "qs";

//axios.defaults.withCredentials = true;
export default {
  name: "Mypage",

  components: {
    //HelloWorld,
  },
  data: () => ({
    username: "",
    password: "",
    otp: "",

    dialog: true,
    valid: true,

    verify_otp: false,

    firstName: "",
    lastName: "",
    email: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    loginEmailRules: [
      (v) => !!v || "필수입력 항목입니다.",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    emailRules: [
      (v) => !!v || "필수입력 항목입니다.",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    show1: false,
    rules: {
      required: (value) => !!value || "필수입력 항목입니다.",
      min: (v) => (v && v.length >= 8) || "8자 이상으로 입력해주세요.",
    },
    usernameRules: {
      required: (value) => !!value || "필수입력 항목입니다.",
      min: (v) => (v && v.length >= 11) || "휴대폰 번호를 정확히 입력해주세요.",
    },
  }),
  computed: {
    passwordMatch() {
      return () =>
        this.password === this.verify || "입력된 비밀번호가 일치하지 않습니다.";
    },
  },
  methods: {
    validate() {
      if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    async formSubmit() {
      if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...

        var data = qs.stringify({
          type: "otp_send",
          to: this.username,
        });

        var config = {
          method: "post",
          url: "//lotto7777.whynot.so/api/msg/",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            //'Access-Control-Allow-Origin': 'localhost:8080',
            "Set-Cookie": "Test1=TestCookieValue1; Secure; SameSite=None",
          },
          //withCredentials: true,
          data: data,
        };

        axios(config)
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "인증번호가 발송되었습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
                //persistent:true,
                persistent: true,
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);

              this.verify_otp = true;

              setTimeout(() => {
                this.$refs.otp.focus();
              }, 1000);

              //this.$router.push(`/experts/${this.category}`);
            } else {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "네트워크오류가 발생했습니다.",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      /*
        const refEmail = await this.$refs.refEmail.validate()
        if (!refEmail.valid) {
          alert(refEmail.errors[0])
          return false
        }
        const refPassword = await this.$refs.refPassword.validate()
        if (!refPassword.valid) {
          alert(refPassword.errors[0])
          return false
        }

      this.$store
        .dispatch("login", {
          //login Action
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);

            this.$router.push({
              name: "Mypage",
            });
          }
        })
        .catch(({ message }) => alert(message));

      return true;
      */
    },

    async JoinSubmit() {
      if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...

        var data = qs.stringify({
          type: "otp_verify",
          action: "join",
          to: this.username,
          otp: this.otp,
          password: this.password,
        });

        var config = {
          method: "post",
          url: "//lotto7777.whynot.so/api/msg/",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            //'Access-Control-Allow-Origin': 'localhost:8080',
            "Set-Cookie": "Test1=TestCookieValue1; Secure; SameSite=None",
          },
          //withCredentials: true,
          data: data,
        };
        axios(config)
          .then((response) => {
            console.log(response);
            if (response.data.success) {
              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: "시작하는 중",
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
                //persistent:true,
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);
              //this.verify_otp = true;

              // this.$router.push(`/experts/${this.category}`);

              this.$store
                .dispatch("login", {
                  //login Action
                  username: this.username,
                  password: this.password,
                })
                .then((response) => {
                  if (response.status == 200) {
                    console.log(response);

                    this.$router.push({
                      name: "Mypage",
                    });
                  }
                })
                .catch(({ message }) => alert(message));
            } else {
              let errorTitle = "네트워크오류가 발생했습니다.";
              if (response.data.data.msg == "invalid") {
                errorTitle = "인증번호가 맞지 않습니다.";
              }
              if (response.data.data.msg == 48) {
                errorTitle = "비밀번호가 맞지않습니다.";
              }
              if (response.data.data.msg == 38) {
                errorTitle = "이미 가입된 아이디가 있습니다.";
              }
              if (response.data.data.msg == 35) {
                errorTitle = "필수 입력 항목을 확인해주세요.";
              }
              if (response.data.data.msg == 36) {
                errorTitle = "이메일형식이 맞지 않습니다.";
              }
              if (response.data.data.msg == "expire") {
                errorTitle = "만료된 인증번호입니다.";
              }
              if (response.data.data.msg == "invalid") {
                errorTitle = "인증번호가 유효하지않습니다.";
              }

              let alertDialogInfo = {
                timeout: 1000,
                //emoji: "😆",
                title: errorTitle,
                //firstLineText: "도움에 감사드립니다",
                //secondLineText: "",
                // thirdLineText: "셋째줄",
              };
              this.$store.dispatch("openAlertDialog", alertDialogInfo);
            }
          })
          .catch((error) => {
            console.log(error.response);
            let response = error.response;

            let errorTitle = "네트워크오류가 발생했습니다.";
            if (response.data.data.msg == "invalid") {
              errorTitle = "인증번호가 맞지 않습니다.";
            }
            if (response.data.data.msg == 48) {
              errorTitle = "비밀번호가 ";
            }
            if (response.data.data.msg == 38) {
              errorTitle = "이미 가입된 아이디가 있습니다.";
            }
            if (response.data.data.msg == 35) {
              errorTitle = "필수 입력 항목을 확인해주세요.";
            }
            if (response.data.data.msg == 36) {
              errorTitle = "이메일형식이 맞지 않습니다.";
            }
            if (response.data.data.msg == "expire") {
              errorTitle = "만료된 인증번호입니다.";
            }
            if (response.data.data.msg == "invalid") {
              errorTitle = "인증번호가 유효하지않습니다.";
            }

            let alertDialogInfo = {
              timeout: 1000,
              //emoji: "😆",
              title: errorTitle,
              //firstLineText: "도움에 감사드립니다",
              //secondLineText: "",
              // thirdLineText: "셋째줄",
            };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
          });
      }

      /*
        const refEmail = await this.$refs.refEmail.validate()
        if (!refEmail.valid) {
          alert(refEmail.errors[0])
          return false
        }
        const refPassword = await this.$refs.refPassword.validate()
        if (!refPassword.valid) {
          alert(refPassword.errors[0])
          return false
        }

      this.$store
        .dispatch("login", {
          //login Action
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);

            this.$router.push({
              name: "Mypage",
            });
          }
        })
        .catch(({ message }) => alert(message));

      return true;
      */
    },

    getPhoneMask(val) {
      let res = this.getMask(val);
      this.username = res;
      //서버 전송 값에는 '-' 를 제외하고 숫자만 저장
      //this.model.username = this.contact.replace(/[^0-9]/g, '')
    },

    getMask(phoneNumber) {
      if (!phoneNumber) return phoneNumber;
      phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

      let res = "";
      if (phoneNumber.length < 3) {
        res = phoneNumber;
      } else {
        if (phoneNumber.substr(0, 2) == "02") {
          if (phoneNumber.length <= 5) {
            //02-123-5678
            res = phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2, 3);
          } else if (phoneNumber.length > 5 && phoneNumber.length <= 9) {
            //02-123-5678
            res =
              phoneNumber.substr(0, 2) +
              "-" +
              phoneNumber.substr(2, 3) +
              "-" +
              phoneNumber.substr(5);
          } else if (phoneNumber.length > 9) {
            //02-1234-5678
            res =
              phoneNumber.substr(0, 2) +
              "-" +
              phoneNumber.substr(2, 4) +
              "-" +
              phoneNumber.substr(6);
          }
        } else {
          if (phoneNumber.length < 8) {
            res = phoneNumber;
          } else if (phoneNumber.length == 8) {
            res = phoneNumber.substr(0, 4) + "-" + phoneNumber.substr(4);
          } else if (phoneNumber.length == 9) {
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 3) +
              "-" +
              phoneNumber.substr(6);
          } else if (phoneNumber.length == 10) {
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 3) +
              "-" +
              phoneNumber.substr(6);
          } else if (phoneNumber.length > 10) {
            //010-1234-5678
            res =
              phoneNumber.substr(0, 3) +
              "-" +
              phoneNumber.substr(3, 4) +
              "-" +
              phoneNumber.substr(7);
          }
        }
      }

      return res;
    },
  },
};
</script>
<style></style>
